import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ServicesList from "./ServicesList"

const Services = () => {
  return (
    <main className="wrapper services">
      <StaticQuery
        query={graphql`
          query {
            hydromat {
              services {
                id
                name
                description
                yellowColor
                provider {
                  id
                  name
                  phone
                  email
                }
                slug
                asset {
                  ... on HYDROMAT_Asset {
                    id
                    url
                  }
                }
              }
            }
          }
        `}
        render={({ hydromat: { services } }) => (
          <ServicesList services={services} />
        )}
      />
    </main>
  )
}

export default Services
