import React from "react"
import { Link } from "gatsby"

const ServicesItem = ({ service }) => {
  const assignColor = isYellow => {
    return isYellow ? "services-list-item--yellow" : "services-list-item--blue"
  }

  return (
    <div className={`services-list-item ${assignColor(service.yellowColor)}`}>
      <div className="item-wrapper">
        <img
          className="item-image"
          src={service.asset[1].url}
          alt={service.slug}
        />
        <div className="item-info">
          <div className="info-content">
            <h3 className="content-header">{service.name}</h3>
            <p
              className="content-description"
              dangerouslySetInnerHTML={{ __html: service.description }}
            ></p>
          </div>
          <div className="info-actions">
            <button className="actions-btn">
              <a aria-label={`zadzwoń pod numer ${service.provider.phone}`} href={`tel:+48${service.provider.phone}`}>Zadzwoń</a>
            </button>
            <button className="actions-btn">
              <Link to={`/uslugi/${service.slug}`}>Więcej</Link>
            </button>
          </div>
        </div>
        <div className="hero-bg"></div>
      </div>
    </div>
  )
}

export default ServicesItem
