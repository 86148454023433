import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from '../components/servicesSection/Services'

const ServicesPage = () => (
  <Layout>
    <Seo title="Usługi" />
    <Services />
  </Layout>
)

export default ServicesPage