import React from "react"
import ServicesItem from "./ServicesItem"

const ServicesList = ({ services }) => {
  const generatedServices = services.map(service => (
    <ServicesItem key={service.id} service={service} />
  ))

  return <section className="services-list">{generatedServices}</section>
}

export default ServicesList
